<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('pos_stores.pos_store') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('pos_stores.name') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name" class="form-control"
                       :class="validation && validation.name ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_stores.address') }}</label>
                <input type="text" v-model="data.address" class="form-control"
                       :class="validation && validation.address ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.address" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.address[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_stores.manager') }}</label>
                <input type="text" v-model="data.manager" class="form-control"
                       :class="validation && validation.manager ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.manager" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.manager[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_stores.mobile') }}</label>
                <vue-tel-input v-model="data.mobile"
                               :class="validation && validation.mobile ? 'is-invalid' : ''"></vue-tel-input>
                <span v-if="validation && validation.mobile" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.mobile[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_stores.mobile_2') }}</label>
                <vue-tel-input v-model="data.mobile2"
                               :class="validation && validation.mobile2 ? 'is-invalid' : ''"></vue-tel-input>
                <span v-if="validation && validation.mobile2" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.mobile2[0] }}
                            </span>
              </div>
            </div>
          </div>

          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>&ensp;</label>
                <b-form-checkbox size="lg" v-model="data.is_allow_invoice_return" name="check-button" switch>{{ $t('pos_stores.is_allow_invoice_return') }}
                </b-form-checkbox>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_stores.no_of_day_return_invoice') }}</label>
                <input type="number" v-model="data.no_of_day_return_invoice" class="form-control"
                       :class="validation && validation.no_of_day_return_invoice ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.no_of_day_return_invoice" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.no_of_day_return_invoice[0] }}
                            </span>
              </div>
            </div>
          </div>

        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_stores.default_bank') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <multiselect v-model="bank"
                               :class="validation && validation.bank_id ? 'is-invalid' : ''"
                               :placeholder="$t('pos_stores.default_bank')"
                               label="name"
                               track-by="id"
                               :options="banks"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend" v-if="$can('banks.create')">
                    <a class="btn btn-primary" href="/finances/banks/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
<!--                <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('pos_stores.default_bank') }}.</span>-->
                <span v-if="validation && validation.bank_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.bank_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_stores.default_cash_treasury') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <multiselect v-model="treasury_cash"
                               :class="validation && validation.treasury_cash_id ? 'is-invalid' : ''"
                               :placeholder="$t('pos_stores.default_cash_treasury')"
                               label="name"
                               track-by="id"
                               :options="treasuries"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend" v-if="$can('treasuries.create')">
                    <a class="btn btn-primary" href="/finances/treasuries/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
<!--                <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('pos_stores.default_cash_treasury') }}.</span>-->
                <span v-if="validation && validation.treasury_cash_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.treasury_cash_id[0] }}
                            </span>
              </div>
<!--              <div class="col-lg-6 mb-5">-->
<!--                <label>{{ $t('pos_stores.default_card_treasury') }}<span class="text-danger">*</span></label>-->
<!--                <div class="input-group">-->
<!--                  <multiselect v-model="treasury_card"-->
<!--                               :class="validation && validation.treasury_card_id ? 'is-invalid' : ''"-->
<!--                               :placeholder="$t('pos_stores.default_card_treasury')"-->
<!--                               label="name"-->
<!--                               track-by="id"-->
<!--                               :options="treasuries"-->
<!--                               :multiple="false"-->
<!--                               :taggable="false"-->
<!--                               :show-labels="false"-->
<!--                               :show-no-options="false"-->
<!--                               :show-no-results="false">-->
<!--                  </multiselect>-->
<!--                  <div class="input-group-prepend" v-if="$can('treasuries.create')">-->
<!--                    <a class="btn btn-primary" href="/finances/treasuries/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>-->
<!--                  </div>-->
<!--                </div>-->
<!--&lt;!&ndash;                <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('pos_stores.default_card_treasury') }}.</span>&ndash;&gt;-->
<!--                <span v-if="validation && validation.treasury_card_id" class="fv-plugins-message-container invalid-feedback">-->
<!--                                {{ validation.treasury_card_id[0] }}-->
<!--                            </span>-->
<!--              </div>-->
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_stores.default_customer') }}<span class="text-danger">*</span></label>
                <div class="form-group row">
                  <div class="col-sm-8">
                    <multiselect
                        id="customer_id"
                        v-model="customer"
                        :placeholder="$t('pos_stores.default_customer')"
                        label="fullname"
                        track-by="id"
                        :options="customers"
                        :multiple="false"
                        :taggable="false"
                        :show-labels="false"
                        :show-no-options="false"
                        :show-no-results="false"
                        @search-change="getCustomers($event)">
                    </multiselect>
                    <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('pos_stores.default_customer') }}.</span>
                    <span v-if="validation && validation.customer_id" class="fv-plugins-message-container invalid-feedback">
                                                {{ validation.customer_id[0] }}
                                            </span>
                  </div>
                  <div class="col-sm-4">
                    <button class="btn btn-primary" @click="showModal">{{ $t('add_new') }}</button>
                  </div>

                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_stores.default_inventory') }}<span class="text-danger">*</span></label>
                <div class="input-group mb-3">
                  <multiselect v-model="inventory"
                               :placeholder="$t('pos_stores.default_inventory')"
                               label="name"
                               track-by="id"
                               :options="inventories"
                               :multiple="false"
                               :class="validation && validation.inventory_id ? 'is-invalid' : ''"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/inventories/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.inventory_id"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.inventory_id[0] }}
              </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_stores.default_currency') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                    <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                  </select>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                            {{ validation.currency_id[0] }}
                        </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('pos_stores.default_payment_method') }}<span class="text-danger">*</span></label>
                <select name="" id="payment_method" v-model="data.payment_method" class="custom-select" :class="validation && validation.payment_method ? 'is-invalid' : ''">
                  <option v-for="row in payment_methods" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.payment_method" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.payment_method[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>&ensp;</label>
                <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('status') }}
                </b-form-checkbox>
              </div>
              <div class="col-lg-6 mb-5">
                <b-form-checkbox size="lg" v-model="data.is_allow_skip_close_cash" name="check-button" switch>
                  {{ $t('pos_settings.is_allow_skip_close_cash') }}
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isEditing" class="row mt-5">
        <div class="col-md-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('pos_stores.devices_list') }}</h6>
          </div>
          <div class="bg-white">
            <table class="table table-row-bordered">
              <thead>
              <tr>
                <th>{{ $t('pos_stores.device') }}</th>
<!--                <th>{{ $t('pos_stores.customer') }}</th>-->
<!--                <th>{{ $t('pos_stores.account') }}</th>-->
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, index) in data.devices" :key="index">
                <td>{{ row.device.name }}</td>
<!--                <td>-->
<!--                  <multiselect-->
<!--                      v-model="row.customer"-->
<!--                      :placeholder="$t('pos_stores.customer')"-->
<!--                      label="fullname"-->
<!--                      track-by="id"-->
<!--                      :options="customers"-->
<!--                      :multiple="false"-->
<!--                      :taggable="false"-->
<!--                      :show-labels="false"-->
<!--                      :show-no-options="false"-->
<!--                      :show-no-results="false"-->
<!--                      @input="setCustomer(index)"-->
<!--                      @search-change="getCustomers($event)">-->
<!--                  </multiselect>-->
<!--                </td>-->
<!--                <td>-->
<!--                  <multiselect-->
<!--                      v-model="row.account"-->
<!--                      :placeholder="$t('pos_stores.account')"-->
<!--                      label="label"-->
<!--                      track-by="id"-->
<!--                      :options="accounts"-->
<!--                      :multiple="false"-->
<!--                      :taggable="false"-->
<!--                      :show-labels="false"-->
<!--                      :show-no-options="false"-->
<!--                      :show-no-results="false"-->
<!--                      @input="setAccount(index)"-->
<!--                      @search-change="getAccounts($event)">-->
<!--                  </multiselect>-->
<!--                </td>-->
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-md-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="my-auto text-white">{{ $t('pos_stores.payment_methods_list') }}</h6>
            <button type="button" class="btn btn-primary" @click="addMoreInRepeater">{{ $t('add_more') }}</button>
          </div>

          <div class="bg-white">
            <table class="table table-row-bordered" @keyup.alt.enter="addMoreInRepeater" @keyup.alt.46="removeFromRepeater(0)">
              <thead>
              <tr>
                <th>{{ $t('pos_stores.payment_method') }}</th>
                <th>{{ $t('pos_stores.treasury_or_back') }}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, index) in store_payment_method" :key="index">
                <td>
                  <select name="" id="payment_method" v-model="row.payment_method_id" @input="selectPaymentMethod(index)" class="custom-select">
                    <option v-for="row in payment_methods" :value="row.id" :key="'payment_method_'+index+'_'+row.id">{{ row.title }}</option>
                  </select>
                </td>
                <td>
                  <select v-if="row.type == 'treasury' || row.type == 'treasuries' " name="" id="treasury" v-model="row.type_id" class="custom-select">
                    <option v-for="row in treasuries" :value="row.id" :key="'treasury_'+index+'_'+row.id">{{ row.name }}</option>
                  </select>

                  <select  v-if="row.type == 'bank'" name="" id="bank" v-model="row.type_id" class="custom-select">
                    <option v-for="row in banks" :value="row.id" :key="'treasury_'+index+'_'+row.id">{{ row.name }}</option>
                  </select>


                </td>
                <td class="text-center">
                  <v-icon style="color: #dc3545;" small @click="removeFromRepeater(index)">mdi-delete</v-icon>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('pos_stores.description') }}</label>
                  <textarea type="text" v-model="data.description" class="form-control"
                            :class="validation && validation.description ? 'is-invalid' : ''"></textarea>
                  <span v-if="validation && validation.description" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.description[0] }}
                            </span>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::User-->

    <b-modal ref="modal" size="lg" hide-footer :title="$t('customers.add_new_customer')">
      <customer-form @hide-modal="hideModal()" @handling-data="getDataAfterCreateNewCustomer" :type="'point_of_sale'"></customer-form>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import CustomerForm from "@/view/content/forms/CustomerForm";

export default {
  name: "from-pos-stores",
  components: {'customer-form': CustomerForm,},
  data() {
    return {
      mainRoute: 'pos/pos-stores',
      mainRouteDependency: 'base/dependency',

      idEdit: this.$route.params.id ? this.$route.params.id : null,
      data: {
        id: null,
        name: null,
        address: null,
        manager: null,
        mobile: null,
        mobile2: null,
        description: null,
        is_active: true,
        is_allow_skip_close_cash: false,
        devices: [],

        bank_id: null,
        treasury_cash_id: null,
        treasury_card_id: null,
        inventory_id: null,
        currency_id: null,
        customer_id: null,
        payment_method: null,

        is_allow_invoice_return: true,
        no_of_day_return_invoice: null,
      },
      isEditing: false,
      validation: null,
      devices_list: [],
      customers: [],
      accounts: [],

      treasuries: [],
      treasury_cash: null,
      treasury_card: null,
      banks: [],
      bank: null,
      inventories: [],
      inventory: null,
      customer: null,
      currencies: [],
      payment_methods: [],
      repeater_payment_method_form: { payment_method_id: null, type: null, type_id: null},
      store_payment_method: [],
    };
  },

  watch: {
    'data.name': function (val) {
      if (val) {
        this.data.name = val.slice(0, 254);
      }
    },
    treasury_card: function (val) {
      if (val && val.id) {
        this.data.treasury_card_id = val.id;
      } else {
        this.data.treasury_card_id = null;
      }
    },
    treasury_cash: function (val) {
      if (val && val.id) {
        this.data.treasury_cash_id = val.id;
      } else {
        this.data.treasury_cash_id = null;
      }
    },
    bank: function (val) {
      if (val && val.id) {
        this.data.bank_id = val.id;
      } else {
        this.data.bank_id = null;
      }
    },
    inventory: function (val) {
      if (val) {
        this.data.inventory_id = val.id;
      } else {
        this.data.inventory_id = null;
      }
    },
    customer: function (val) {
      if (val) {
        this.data.customer_id = val.id;
      } else {
        this.data.customer_id = null;
      }
    },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
        store_payment_method: this.store_payment_method,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/pos/pos-stores');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
        store_payment_method: this.store_payment_method,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/pos/pos-stores');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    getData() {
      this.reloadUploadAttachment = false;
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        this.data.id = response.data.data.id;
        this.data.name = response.data.data.name;
        this.data.address = response.data.data.address;
        this.data.manager = response.data.data.manager;
        this.data.mobile = response.data.data.mobile;
        this.data.mobile2 = response.data.data.mobile2;
        this.data.description = response.data.data.description;
        this.data.is_active = response.data.data.is_active;
        this.data.is_allow_skip_close_cash = response.data.data.is_allow_skip_close_cash;
        this.data.devices = response.data.data.devices;

        this.data.bank_id = response.data.data.bank_id;
        this.data.treasury_cash_id = response.data.data.treasury_cash_id;
        this.data.treasury_card_id = response.data.data.treasury_card_id;
        this.data.inventory_id = response.data.data.inventory_id;
        this.data.currency_id = response.data.data.currency_id;
        this.data.customer_id = response.data.data.customer_id;
        this.data.payment_method = response.data.data.payment_method;
        this.data.is_allow_invoice_return = response.data.data.is_allow_invoice_return;
        this.data.no_of_day_return_invoice = response.data.data.no_of_day_return_invoice;

        this.treasury_cash = response.data.data.treasury_cash;
        this.treasury_card = response.data.data.treasury_card;
        this.bank = response.data.data.bank;
        this.inventory = response.data.data.inventory;
        this.customer = response.data.data.customer;

        this.store_payment_method = response.data.data.store_payment_method? response.data.data.store_payment_method :[];

        if (!response.data.data.devices || (response.data.data.devices && response.data.data.devices.length <= 0)) {
          this.getDevices();
        }
      });
    },
    getDevices() {
      ApiService.get(this.mainRouteDependency + "/pos_device/" + this.idEdit).then((response) => {
        this.data.devices = response.data.data.map((row) => {
          return {
            id: null,
            store_id: this.idEdit,
            device_id: row.id,
            device: row,
            customer_id: null,
            customer: null,
            account_id: null,
            account: null,
          }
        })
      });
    },
    setCustomer(index) {
      this.data.devices[index].customer_id = this.data.devices[index].customer.id
    },
    getCustomers(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/pos_default_customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      } else {
        this.customers = [];
      }
    },
    getAccounts(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/account_parents_leaf_nodes`, {params: {name: filter}}).then((response) => {
          this.accounts = response.data.data;
        });
      } else {
        this.accounts = [];
      }
    },
    setAccount(index) {
      this.data.devices[index].account_id = this.data.devices[index].account.id
    },

    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getTreasuries() {
      ApiService.get(`${this.mainRouteDependency}/treasuries`).then((response) => {
        this.treasuries = response.data.data;
      });
    },
    getBanks() {
        ApiService.get(`${this.mainRouteDependency}/banks`).then((response) => {
          this.banks = response.data.data;
        });

    },
    // getBanks(filter) {
    //   if (filter && filter.length >= 3) {
    //     ApiService.get(`${this.mainRouteDependency}/banks`, {params: {filter: filter}}).then((response) => {
    //       this.banks = response.data.data;
    //     });
    //   } else {
    //     this.banks = [];
    //   }
    // },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },
    getPaymentMethods() {
      ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
        this.payment_methods = response.data.data;
      });
    },

    showModal() {
      this.$refs['modal'].show()
    },
    hideModal() {
      this.$refs['modal'].hide();
    },
    getDataAfterCreateNewCustomer(object) {
      this.customer = object;
      this.data.customer_id = object.id;
    },
    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;
        this.data.inventory_id = response.data.data.inventory_id;
        this.inventory = response.data.data.inventory;
      });
    },
    selectPaymentMethod: _.debounce(function (index) {
      let _payment_method = this.payment_methods.find((row)=> row.id == this.store_payment_method[index].payment_method_id);

      if (_payment_method)
        this.store_payment_method[index].type = _payment_method.cash_type == 'treasury' ? 'treasuries': _payment_method.cash_type;
      else
        this.store_payment_method[index].type = null;
    }, 100),

    addMoreInRepeater(){
      this.store_payment_method.unshift(this.repeater_payment_method_form);
      this.repeater_payment_method_form = {payment_method_id: null, type: null, type_id: null};
    },
    removeFromRepeater(index){
      this.store_payment_method.splice(index, 1);
    },
  },

  mounted() {

    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.pos_stores"),
      route: '/pos/pos-stores'
    }, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    this.getCurrencies();
    this.getInventories();
    this.getPaymentMethods();
    this.defaultDataForUser();
    this.getTreasuries();
    this.getBanks();

    if (this.idEdit) {
      this.getData();
    }

  },
};
</script>


